import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ClockIcon,
  DollarSignIcon,
  LockIcon,
  SettingsIcon,
  ToggleRightIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Share2 } from "lucide-react";
import { AuthContext } from "hooks/AuthContext";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { auth } from "../firebase";
import axios from "../../node_modules/axios/index";
import { Pencil } from "lucide-react";
import { Facebook, Instagram, Twitter } from "lucide-react";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import StripeLogo from "./stripeLogo.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const Settings = () => {
  const [activeSection, setActiveSection] = useState("general");
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const [storeId, setStoreId] = useState(null);
  const [storeName, setStoreName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeZipcode, setStoreZipcode] = useState("");
  const [storeImage, setStoreImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  const [success, setSuccess] = useState("");
  const [currentPickupStart, setCurrentPickupStart] = useState("N/A");
  const [currentPickupEnd, setCurrentPickupEnd] = useState("N/A");
  const [newPickupStart, setNewPickupStart] = useState("NO_CHANGE");
  const [newPickupEnd, setNewPickupEnd] = useState("NO_CHANGE");
  const timeOptions = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchPickupTimes = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/stores/pickupTimeRange`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ uid: user.uid }),
            }
          );
          const data = await response.json();
          if (data.pickupTimeRange) {
            setCurrentPickupStart(data.pickupTimeRange.start || "N/A");
            setCurrentPickupEnd(data.pickupTimeRange.end || "N/A");
          }
        } catch (error) {
          console.error("Error fetching pickup times:", error);
        }
      }
    };

    fetchPickupTimes();
  }, []);

  const handleSavePickupTimes = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/stores/updatePickupTimeRange`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              uid: user.uid,
              pickupTimeRange: {
                start:
                  newPickupStart === "NO_CHANGE"
                    ? currentPickupStart
                    : newPickupStart,
                end:
                  newPickupEnd === "NO_CHANGE"
                    ? currentPickupEnd
                    : newPickupEnd,
              },
            }),
          }
        );
        if (response.ok) {
          console.log("Pickup times updated successfully");
          Toastify({
            text: `Saved Changes`,
            duration: 1500,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
          }).showToast();

          setCurrentPickupStart(
            newPickupStart === "NO_CHANGE" ? currentPickupStart : newPickupStart
          );
          setCurrentPickupEnd(
            newPickupEnd === "NO_CHANGE" ? currentPickupEnd : newPickupEnd
          );
          setNewPickupStart("NO_CHANGE");
          setNewPickupEnd("NO_CHANGE");
          // You might want to add a success message here
        } else {
          throw new Error("Failed to update pickup times");
        }
      } catch (error) {
        console.error("Error updating pickup times:", error);
        // You might want to add an error message here
      }
    }
  };
  useEffect(() => {
    setPasswordRequirements({
      length: newPassword.length >= 6,
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
    });
  }, [newPassword]);

  const isPasswordValid = Object.values(passwordRequirements).every(Boolean);

  const handleChangePassword = async () => {
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    if (!isPasswordValid) {
      setError("New password doesn't meet all requirements");
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setSuccess("Password updated successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setError(error.message);
    }
  };
  const [socials, setSocials] = useState({
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
  });

  // Extract storeId from the URL

  // Fetch socials on component mount
  useEffect(() => {
    const fetchSocials = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/socials/socials`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ uid: user.uid }),
            }
          );
          const data = await response.json();
          if (data && data.socials) {
            setSocials(data.socials);
          } else {
            // Set default values if socials are not found
            setSocials({
              facebookUrl: "",
              instagramUrl: "",
              twitterUrl: "",
            });
          }
        } catch (error) {
          console.error("Error fetching socials:", error);
          // Set default values in case of error
          setSocials({
            facebookUrl: "",
            instagramUrl: "",
            twitterUrl: "",
          });
        }
      }
    };

    fetchSocials();
  }, []);

  const handleInputChange = (e) => {
    setSocials({ ...socials, [e.target.name]: e.target.value });
  };

  const handleSocialSubmit = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/socials/update`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...socials, uid: user.uid }),
        }
      );

      if (response.ok) {
        console.log("Social URLs updated successfully");
        Toastify({
          text: `Saved Changes`,
          duration: 1500,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
        // You might want to add a success message here
      } else {
        throw new Error("Failed to update");
      }
    } catch (error) {
      console.error("Error updating socials:", error);
      // You might want to add an error message here
    }
  };

  useEffect(() => {
    const fetchStoreDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/stores/getStore`,
            {
              uid: user.uid,
            }
          );
          const data = response.data;
          setStoreName(data.name);
          setStoreAddress(data.address);
          setStoreZipcode(data.zipcode);
          setImagePreview(data.image);
          setIsActive(data.active); // Add this line
        } catch (error) {
          console.error("Failed to fetch store details", error);
          setError("Failed to load store details. Please try again.");
        }
      }
    };

    fetchStoreDetails();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setStoreImage(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) return;

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("name", storeName);
    formData.append("address", storeAddress);
    formData.append("zipcode", storeZipcode);
    formData.append("uid", user.uid);
    if (storeImage) {
      formData.append("image", storeImage);
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/stores/update`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Toastify({
        text: `Saved Changes`,
        duration: 1500,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
      // Show success message
      console.log("Store updated successfully", response.data);
      // You might want to update the UI or show a success message here
    } catch (error) {
      console.error("Failed to update store details", error);
      setError("Failed to update store details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  //stripe
  const handleCreateAccount = async () => {
    setAccountCreatePending(true);
    setError(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ASSET_URL}/account`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ firebaseUid: auth.currentUser.uid }),
        }
      );
      const data = await response.json();
      setAccountCreatePending(false);

      if (data.account) {
        setConnectedAccountId(data.account);
      } else {
        throw new Error(data.error || "Failed to create Stripe account");
      }
    } catch (error) {
      console.error("Error creating Stripe account:", error);
      setError(true);
      setAccountCreatePending(false);
    }
  };
  const [isAccountComplete, setIsAccountComplete] = useState(false);
  const [accountLink, setAccountLink] = useState(null);

  // Add this function to check the account status
  const checkAccountStatus = async () => {
    if (!connectedAccountId) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ASSET_URL}/check_account_status`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ accountId: connectedAccountId }),
        }
      );
      const data = await response.json();

      setIsAccountComplete(data.isComplete);
      setAccountLink(data.accountLink);
    } catch (error) {
      console.error("Failed to check account status", error);
    }
  };

  // Call this function in useEffect after setting connectedAccountId
  useEffect(() => {
    if (connectedAccountId) {
      checkAccountStatus();
    }
  }, [connectedAccountId]);

  const handleAddInformation = async () => {
    setAccountLinkCreatePending(true);
    setError(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ASSET_URL}/account_link`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ account: connectedAccountId }),
        }
      );
      const data = await response.json();
      setAccountLinkCreatePending(false);

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error(data.error || "Failed to generate account link");
      }
    } catch (error) {
      console.error("Error generating account link:", error);
      setError(true);
      setAccountLinkCreatePending(false);
    }
  };

  useEffect(() => {
    const checkAccount = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_ASSET_URL}/check_account`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uid: user.uid }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          setConnectedAccountId(data.stripeAccountId);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Failed to check account", error);
      }
    };

    checkAccount();
  }, []);
  const handleActiveToggle = async (checked) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/stores/updateActiveStatus`,
          {
            uid: user.uid,
            active: checked,
          }
        );
        if (response.status === 200) {
          setIsActive(checked);
          Toastify({
            text: `Saved Changes`,
            duration: 1500,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
          }).showToast();
        }
      } catch (error) {
        console.error("Failed to update store status", error);
        setError("Failed to update store status. Please try again.");
      }
    }
  };
  return (
    <div>
      <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
        <div className="hidden border-r bg-gray-100/40 lg:block dark:bg-gray-800/40">
          <div className="flex h-full max-h-screen flex-col gap-2">
            <div className="flex h-[60px] items-center border-b px-6">
              <h1 className="text-xl font-semibold">Settings</h1>
            </div>
            <div className="flex-1 overflow-auto py-2">
              <nav className="grid items-start px-4 text-sm font-medium">
                <Link
                  href="#"
                  onClick={() => setActiveSection("general")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "general"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <SettingsIcon className="h-4 w-4" />
                  General Settings
                </Link>
                <Link
                  href="#"
                  onClick={() => setActiveSection("socials")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "socials"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <Share2 className="h-4 w-4" />
                  Socials
                </Link>
                <Link
                  href="#"
                  onClick={() => setActiveSection("passwords")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "passwords"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <LockIcon className="h-4 w-4" />
                  Passwords
                </Link>
                <Link
                  href="#"
                  onClick={() => setActiveSection("real-world")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "real-world"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <ClockIcon className="h-4 w-4" />
                  Real World
                </Link>
                <Link
                  href="#"
                  onClick={() => setActiveSection("financials")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "financials"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <DollarSignIcon className="h-4 w-4" />
                  Financials
                </Link>
                <Link
                  href="#"
                  onClick={() => setActiveSection("toggles")}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-gray-900 dark:hover:text-gray-50 ${
                    activeSection === "toggles"
                      ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                  prefetch={false}
                >
                  <ToggleRightIcon className="h-4 w-4" />
                  Toggles
                </Link>
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mt-3 ml-3">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink>
                    <Link to="/st/dashboard">Dashboard</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />

                <BreadcrumbItem>
                  <BreadcrumbPage>
                    <Link to="/st/settings">Settings</Link>
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
            {activeSection === "general" && (
              <div id="general" className="space-y-6">
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">General Settings</h2>
                    <Button type="submit" size="sm" disabled={isLoading}>
                      {isLoading ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                  {error && <div className="text-red-500">{error}</div>}
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className="space-y-2">
                      <Label htmlFor="store-name">Store Name</Label>
                      <Input
                        id="store-name"
                        value={storeName}
                        onChange={(e) => setStoreName(e.target.value)}
                      />
                    </div>
                    <div className="space-y-2 relative group">
                      <Label htmlFor="address">Address</Label>
                      <Input
                        id="address"
                        value={storeAddress}
                        readOnly
                        className="cursor-not-allowed"
                      />
                      <div className="absolute top-0 left-0  w-full h-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center text-white text-xs rounded-md">
                        Please contact support to change
                      </div>
                    </div>
                    <div className="space-y-2 relative group">
                      <Label htmlFor="zipcode">Zipcode</Label>
                      <Input
                        id="zipcode"
                        value={storeZipcode}
                        readOnly
                        className="cursor-not-allowed"
                      />
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center text-white text-xs rounded-md">
                        Please contact support to change
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="image-upload">Store Image</Label>
                      <br />
                      <div className="relative inline-block">
                        <img
                          src={imagePreview || "/placeholder.svg"}
                          alt="Store Image"
                          width={100}
                          height={100}
                          className="rounded-md object-cover border-2 border-gray-300"
                        />
                        <label
                          htmlFor="image-upload"
                          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-200 cursor-pointer rounded-md"
                        >
                          <Pencil className="text-white" size={24} />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="hidden"
                          id="image-upload"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {activeSection === "socials" && (
              <div id="socials" className="space-y-6">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col text-left">
                    <h2 className="text-xl font-semibold">Socials</h2>
                    <h4 className="text-sm font-semibold text-gray-500">
                      Add your socials to display on your page.
                    </h4>
                  </div>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={isLoading}
                    onClick={handleSocialSubmit}
                  >
                    {isLoading ? "Saving..." : "Save Changes"}
                  </Button>
                </div>

                <div className="mt-4">
                  {/* Facebook Input */}
                  <div className="flex flex-col mt-4">
                    <div className="flex items-center mb-3">
                      <span className="inline-flex items-center justify-center p-2 border border-gray-300 rounded-l">
                        <Facebook icon="Facebook" className="h-5 w-5" />
                      </span>
                      <input
                        type="text"
                        className="form-control flex-1 rounded-r border-gray-300 ml-2"
                        placeholder="Enter Facebook Url"
                        aria-label="Facebook"
                        aria-describedby="facebook-addon"
                        name="facebookUrl"
                        value={socials.facebookUrl}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* Instagram Input */}
                  <div className="flex flex-col">
                    <div className="flex items-center mb-3">
                      <span className="inline-flex items-center justify-center p-2 border border-gray-300 rounded-l">
                        <Instagram icon="Instagram" className="h-5 w-5" />
                      </span>
                      <input
                        type="text"
                        className="form-control flex-1 rounded-r border-gray-300 ml-2"
                        placeholder="Enter Instagram Url"
                        aria-label="Instagram"
                        aria-describedby="instagram-addon"
                        name="instagramUrl"
                        value={socials.instagramUrl}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* Twitter Input */}
                  <div className="flex flex-col">
                    <div className="flex items-center mb-3">
                      <span className="inline-flex items-center justify-center p-2 border border-gray-300 rounded-l">
                        <Twitter icon="Twitter" className="h-5 w-5" />
                      </span>
                      <input
                        type="text"
                        className="form-control flex-1 rounded-r border-gray-300 ml-2"
                        placeholder="Enter Twitter Url"
                        aria-label="Twitter"
                        aria-describedby="twitter-addon"
                        name="twitterUrl"
                        value={socials.twitterUrl}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* Submit Button */}

                  <div className="mt-4 flex justify-center">
                    <div className="flex border p-3 rounded-lg">
                      <h4>Preview:</h4>
                      {socials.facebookUrl && (
                        <div className="mx-2">
                          <Facebook
                            icon="Facebook"
                            className="inline h-5 w-5"
                          />{" "}
                          <a
                            href={socials.facebookUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Facebook
                          </a>
                        </div>
                      )}
                      {socials.instagramUrl && (
                        <div className="mx-2">
                          <Instagram
                            icon="Instagram"
                            className="inline h-5 w-5"
                          />{" "}
                          <a
                            href={socials.instagramUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Instagram
                          </a>
                        </div>
                      )}
                      {socials.twitterUrl && (
                        <div className="mx-2">
                          <Twitter icon="Twitter" className="inline h-5 w-5" />{" "}
                          <a
                            href={socials.twitterUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Twitter
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeSection === "passwords" && (
              <div id="passwords" className="space-y-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold">Change Password</h2>
                </div>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="current-password">Current Password</Label>
                    <Input
                      id="current-password"
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="new-password">New Password</Label>
                    <Input
                      id="new-password"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="confirm-password">
                      Confirm New Password
                    </Label>
                    <Input
                      id="confirm-password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <h3 className="text-sm font-semibold">
                      Password Requirements:
                    </h3>
                    <ul className="list-disc list-inside text-sm">
                      <li
                        className={
                          passwordRequirements.length
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        At least 6 characters
                      </li>
                      <li
                        className={
                          passwordRequirements.uppercase
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        At least 1 uppercase letter
                      </li>
                      <li
                        className={
                          passwordRequirements.lowercase
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        At least 1 lowercase letter
                      </li>
                      <li
                        className={
                          passwordRequirements.number
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        At least 1 number
                      </li>
                    </ul>
                  </div>
                  <Button
                    onClick={handleChangePassword}
                    disabled={
                      !isPasswordValid || newPassword !== confirmPassword
                    }
                  >
                    Change Password
                  </Button>
                  {error && <p className="text-red-500">{error}</p>}
                  {success && <p className="text-green-500">{success}</p>}
                </div>
              </div>
            )}
            {activeSection === "real-world" && (
              <div id="real-world" className="space-y-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold">Pickup Time Range</h2>
                  <Button size="sm" onClick={handleSavePickupTimes}>
                    Save Changes
                  </Button>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div className="space-y-2">
                    <Label htmlFor="current-pickup-start">
                      Current Pickup Time Start
                    </Label>
                    <Input
                      id="current-pickup-start"
                      value={currentPickupStart}
                      disabled
                    />
                    <Label htmlFor="new-pickup-start">
                      New Pickup Time Start
                    </Label>
                    <Select
                      id="new-pickup-start"
                      value={newPickupStart}
                      onValueChange={setNewPickupStart}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select new start time" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="NO_CHANGE">No change</SelectItem>
                        {timeOptions.map((time) => (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="current-pickup-end">
                      Current Pickup Time End
                    </Label>
                    <Input
                      id="current-pickup-end"
                      value={currentPickupEnd}
                      disabled
                    />
                    <Label htmlFor="new-pickup-end">New Pickup Time End</Label>
                    <Select
                      id="new-pickup-end"
                      value={newPickupEnd}
                      onValueChange={setNewPickupEnd}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select new end time" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="NO_CHANGE">No change</SelectItem>
                        {timeOptions.map((time) => (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {activeSection === "financials" && (
              <div id="financials" className="space-y-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold">Financials</h2>
                </div>

                <div className="container">
                  <div className="content">
                    {!connectedAccountId ? (
                      <div className="container mx-auto px-4 py-12 max-w-3xl">
                        <div className="text-center space-y-6 text-start">
                          <h2 className="text-3xl font-bold tracking-tight text-primary">
                            Connect Your Account
                          </h2>
                          <p className="text-xl text-muted-foreground">
                            Create a Stripe account to add your details and get
                            payouts.
                          </p>
                          <Button
                            onClick={handleCreateAccount}
                            size="lg"
                            className="mt-4 px-8 py-3"
                          >
                            Create an account
                          </Button>
                          <h2 className="text-m text-start font-bold tracking-tight text-primary flex items-center">
                            Transactions Powered by
                            <a
                              href="https://stripe.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center"
                            >
                              <img
                                src={StripeLogo}
                                alt="Stripe"
                                className="h-8"
                              />
                            </a>
                          </h2>
                        </div>
                      </div>
                    ) : (
                      <div className="container mx-auto px-4 py-12 max-w-3xl">
                        <div className="text-center space-y-6 text-start">
                          <h2 className="text-3xl font-bold tracking-tight text-primary">
                            {isAccountComplete
                              ? "Your account is set up"
                              : "Add information to start accepting money"}
                          </h2>
                          <p className="text-xl text-muted-foreground">
                            {isAccountComplete
                              ? "Your Stripe account is fully set up. You can edit your details if needed."
                              : "Spockal partners with Stripe to help you receive payments and keep your personal bank and details secure."}
                          </p>
                          {!accountLinkCreatePending && (
                            <Button
                              onClick={handleAddInformation}
                              size="lg"
                              className="mt-4 px-8 py-3"
                            >
                              {isAccountComplete
                                ? "Edit details"
                                : "Add information"}
                            </Button>
                          )}
                          <h2 className="text-m text-start font-bold tracking-tight text-primary flex items-center">
                            Transactions Powered by
                            <a
                              href="https://stripe.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center"
                            >
                              <img
                                src={StripeLogo}
                                alt="Stripe"
                                className="h-8"
                              />
                            </a>
                          </h2>
                        </div>
                      </div>
                    )}
                    {error && <p className="error">Something went wrong!</p>}
                    {(connectedAccountId ||
                      accountCreatePending ||
                      accountLinkCreatePending) && (
                      <div className="dev-callout">
                        {connectedAccountId && (
                          <p>
                            Your connected account ID is:{" "}
                            <code className="bold">{connectedAccountId}</code>
                          </p>
                        )}
                        {accountCreatePending && (
                          <p>Creating a connected account...</p>
                        )}
                        {accountLinkCreatePending && (
                          <p>Creating a new Account Link...</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {activeSection === "toggles" && (
              <div id="toggles" className="space-y-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold">Toggles</h2>
                  <Button size="sm">Save Changes</Button>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Toggle</TableHead>
                      <TableHead>Description</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        <div className="flex items-start space-x-2">
                          <Switch
                            id="active-toggle"
                            checked={isActive}
                            onCheckedChange={handleActiveToggle}
                          />{" "}
                          <label htmlFor="active-toggle">Active/Inactive</label>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className=" text-start">
                          Controls the overall status of the store.
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">
                        <div className="flex items-start space-x-2">
                          <Switch id="delivery-toggle" checked disabled />
                          <label
                            htmlFor="delivery-toggle"
                            className="text-muted-foreground"
                          >
                            Delivery On/Off
                          </label>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-start">
                          Powered by Spockal Delivery
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Settings;
