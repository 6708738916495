import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Fingerprint } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import axios from "axios";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const loginSubmitHandler = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/stores/login`,
        {
          firebaseUid: user.uid,
          email: email,
        }
      );

      if (response.data && response.data.storeId) {
        localStorage.setItem("storeId", response.data.storeId);
        console.log("Login successful");
        navigate("/st/dashboard");
      } else {
        setError("Failed to fetch store information");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="flex min-h-screen justify-center bg-gray-100 dark:bg-gray-900">
      <div>
        <div className="w-full max-w-md p-8 bg-white dark:bg-gray-800 shadow-lg rounded-lg mt-10">
          {/* Combined into one column */}
          <div className="flex flex-col items-center space-y-6">
            <Link
              href="#"
              className="flex items-center space-x-2"
              prefetch={false}
            >
              <Fingerprint className="h-8 w-8 text-indigo-600" />
              <span className="text-xl font-bold text-gray-900 dark:text-gray-50">
                Spockal
              </span>
            </Link>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-50">
              Sign in to your account
            </h2>
            <p className="text-sm text-gray-600 dark:text-gray-400 text-center">
              Enter your username and password to access your account
            </p>

            <form className="space-y-6 w-full" onSubmit={loginSubmitHandler}>
              <div>
                <Label htmlFor="username" className="sr-only">
                  Username
                </Label>
                <Input
                  id="username"
                  name="email"
                  type="text"
                  autoComplete="username"
                  required
                  placeholder="Username"
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-50 dark:placeholder-gray-400"
                />
              </div>
              <div>
                <Label htmlFor="password" className="sr-only">
                  Password
                </Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="Password"
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-50 dark:placeholder-gray-400"
                />
              </div>
              <div>
                <Button type="submit" className="w-full rounded-xl">
                  Sign in
                </Button>
              </div>
            </form>
            <div>
              <div className="text-center">
                <span className="text-gray-600">
                  Already have an account?{" "}
                  <a
                    href="/auth/join"
                    className="text-blue-600 hover:underline"
                  >
                    Sign up
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
