import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

export default function StoreSignup() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    zipcode: "",
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "signup" })
        .then(async (recaptchaToken) => {
          const formDataToSend = new FormData();
          formDataToSend.append("name", formData.name);
          formDataToSend.append("email", formData.email);
          formDataToSend.append("password", formData.password);
          formDataToSend.append("address", formData.address);
          formDataToSend.append("zipcode", formData.zipcode);
          if (formData.image) {
            formDataToSend.append("image", formData.image);
          }
          formDataToSend.append("recaptchaToken", recaptchaToken);

          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/stores/signup`,
              {
                method: "POST",
                body: formDataToSend,
              }
            );

            if (!response.ok) {
              throw new Error("Signup failed");
            }

            const data = await response.json();
            console.log("Signup successful:", data);

            // Redirect to login page after successful signup
            navigate("/auth/storelogin"); // Redirects to /auth/storelogin
          } catch (error) {
            console.error("Error during signup:", error);
            // Handle error (e.g., show error message to user)
          }
        });
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <Card className="w-full max-w-4xl">
        <form onSubmit={handleSubmit}>
          <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
            {/* Left Column */}
            <div className="flex flex-col text-start">
              <CardHeader className="p-0">
                <CardTitle className="text-3xl font-bold">
                  Store Signup
                </CardTitle>
                <CardDescription className="mt-4 text-lg">
                  Create your store account to get started
                </CardDescription>
              </CardHeader>
            </div>
            {/* Right Column */}
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Store Name</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter store name"
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Store Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter store email"
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter password"
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="image">Store Image</Label>
                <div className="flex items-center space-x-4">
                  <Input
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="w-full"
                  />
                  {imagePreview && (
                    <div className="relative w-16 h-16">
                      <img
                        src={imagePreview}
                        alt="Store preview"
                        className="w-full h-full object-cover rounded"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address">Address</Label>
                <Input
                  id="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Enter full address"
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="zipcode">Zipcode</Label>
                <Input
                  id="zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                  placeholder="Enter zipcode"
                  required
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="p-8 flex flex-col space-y-4">
            <Button type="submit" className="w-full">
              Sign Up
            </Button>
            <div className="text-center">
              <span className="text-gray-600">
                Already have an account?{" "}
                <a
                  href="/auth/storelogin"
                  className="text-blue-600 hover:underline"
                >
                  Sign In
                </a>
              </span>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
