import React, { useContext, useState, useEffect } from "react";
import { CloudUploadIcon, XIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { useHttpClient } from "hooks/http-hook";
import { useForm } from "hooks/form-hook";
import { AuthContext } from "hooks/AuthContext";
import { Label } from "@/components/ui/label";
import axios from "axios";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";
import { auth } from "../firebase"; // Ensure this path is correct

const AddProduct = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [enableQuantityTracking, setEnableQuantityTracking] = useState(false);
  const [salePrice, setSalePrice] = useState("");
  const [saleEndDate, setSaleEndDate] = useState("");

  const handleImageUpload = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    const user = auth.currentUser;
    console.log(user);
    if (!user) {
      alert("No user logged in");
      return;
    }
    const uid = user.uid;

    const formData = new FormData();
    formData.append("title", document.getElementById("title").value);
    formData.append("price", document.getElementById("price").value);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    if (salePrice) {
      formData.append("salePrice", salePrice);
    }

    // Check for optional sale end date
    if (saleEndDate) {
      formData.append("saleEndDate", saleEndDate);
    }

    const tags = document.getElementById("tags").value.trim();
    formData.append(
      "tags",
      tags ? JSON.stringify(tags.split(",")) : JSON.stringify([])
    );

    if (enableQuantityTracking) {
      formData.append("quantity", document.getElementById("quantity").value);
    }

    images.forEach((image) => formData.append("images", image, image.name));
    formData.append("uid", uid);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/products`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      console.log("Product creation successful:", response.data);
      navigate("/st/inventory"); // Adjust the route as necessary
    } catch (error) {
      console.error("Product creation failed:", error.response.data);
    }
  };

  return (
    <React.Fragment>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/inventory">Inventory</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>
                <Link to="/st/addproduct">Add Product</Link>
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="max-w-2xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="space-y-8">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">Add Product</h1>
            <p className="mt-2 text-gray-500 dark:text-gray-400">
              Fill out the details to add a new product.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
            enctype="multipart/form-data"
          >
            <div className="sm:col-span-4">
              <Label htmlFor="title">Title</Label>
              <Input id="title" type="text" placeholder="Product Title" />
            </div>
            <div className="sm:col-span-2">
              <Label htmlFor="price">Price</Label>
              <Input id="price" type="number" placeholder="Price" />
            </div>
            {/* Sale Price Input */}
            <div className="sm:col-span-3">
              <Label htmlFor="salePrice">Sale Price (optional)</Label>
              <Input
                id="salePrice"
                type="number"
                placeholder="Sale Price"
                min="0"
                value={salePrice}
                onChange={(e) => setSalePrice(e.target.value)}
              />
            </div>
            {/* Sale End Date Input */}
            <div className="sm:col-span-3">
              <Label htmlFor="saleEndDate">Sale End Date</Label>
              <Input
                id="saleEndDate"
                type="date"
                value={saleEndDate}
                onChange={(e) => setSaleEndDate(e.target.value)}
                disabled={!salePrice} // Disable if salePrice is empty
              />
            </div>

            <div className="sm:col-span-6">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                rows={3}
                placeholder="Product Description"
              />
            </div>
            <div className="sm:col-span-6">
              <div className="flex items-center">
                <Checkbox
                  id="enableQuantityTracking"
                  checked={enableQuantityTracking}
                  onCheckedChange={setEnableQuantityTracking}
                />
                <Label htmlFor="enableQuantityTracking" className="ml-3">
                  Enable Quantity Tracking
                </Label>
              </div>
              {enableQuantityTracking && (
                <div className="mt-4">
                  <Label htmlFor="quantity">Quantity</Label>
                  <Input id="quantity" type="number" placeholder="Quantity" />
                </div>
              )}
            </div>
            <div className="sm:col-span-6">
              <Label htmlFor="images">Images</Label>
              <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 dark:border-gray-700">
                <div className="space-y-1 text-center">
                  <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600 dark:text-gray-400">
                    <label
                      htmlFor="images"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500 dark:bg-gray-950 dark:text-indigo-400 dark:focus-within:ring-indigo-400 dark:focus-within:ring-offset-gray-950"
                    >
                      <span>Upload up to 5 images</span>
                      <Input
                        id="images"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                        className="sr-only"
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
              {images.length > 0 && (
                <div className="mt-4 grid grid-cols-3 gap-4">
                  {images.map((image, index) => (
                    <div key={index} className="relative border">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index + 1}`}
                        width={200}
                        height={200}
                        className="rounded-md object-cover "
                      />
                      <Button
                        variant="ghost"
                        size="icon"
                        className="absolute top-1 right-1 text-gray-900 hover:bg-gray-100 dark:text-gray-50 dark:hover:bg-gray-800 bg-white"
                      >
                        <XIcon className="h-5 w-5" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="sm:col-span-6">
              <Label htmlFor="tags">Tags</Label>
              <Input id="tags" placeholder="Add tags separated by commas" />
            </div>
            <div className="sm:col-span-6 flex justify-end">
              <Button type="submit" size="lg">
                Save Product
              </Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProduct;
